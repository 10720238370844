import * as React from 'react';
import { useState, useEffect } from 'react';
import GlobalStyles from '../styles/globalStyles';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import HomePageTemplate from '../templates/homePageTemplate';
import BackToTopScroll from '../components/backToTopScroll/backToTopScroll';
import { graphql, navigate } from 'gatsby';
import { I18nextProvider } from 'react-i18next';
import i18next from '../i18next';

const IndexPage = ({ location }) => {
  React.useEffect(() => {
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    // if (location?.pathname !== '/') {
    //   navigate('/404');
    // }
  }, []);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
  }, []);
  if (typeof window === 'undefined') {
    return (
      <>
        <I18nextProvider i18n={i18next}>
          <GlobalStyles />

          <Header />
          <HomePageTemplate />
          <Footer />
          <BackToTopScroll />
        </I18nextProvider>
      </>
    );
  } else {
    return (
      <>
        <I18nextProvider i18n={i18next}>
          <GlobalStyles />

          <Header />
          <HomePageTemplate />
          <Footer />
          <BackToTopScroll />
        </I18nextProvider>
      </>
    );
  }
};

export default IndexPage;

export const pageQuery = graphql`
  fragment PageContent on ContentfulContent {
    id
    title
    type
    header
    subText
    elementId
    contentDetails {
      raw
    }
  }
  fragment ContentList on ContentfulContentList {
    id
    title
    type
    header
    subtext
    elementId
    list {
      title
      type
      header
      subText
      elementId
      contentDetails {
        raw
      }
    }
    listDetails {
      raw
    }
  }
  fragment ContentfulListImages on ContentfulListOfImageWithPath {
    id
    type
    title
    listOfImagesWithPath {
      title
      type
      image {
        file {
          url
        }
      }
    }
  }
  fragment TextWithImage on ContentfulTextWithImage {
    id
    title
    type
    image {
      title
      file {
        url
      }
    }
    text {
      text
    }
  }
  fragment FAQContent on ContentfulFaqs {
    id
    title
    type
    header
    elementId
    description
    qna {
      title
      type
      text {
        raw
      }
      buttons {
        raw
      }
    }
  }
  fragment MultimediaText on ContentfulMultimediaText {
    title
    type
    multimediaText: text {
      raw
    }
  }
  fragment ImagesWithPathList on ContentfulListOfImageWithPath {
    id
    title
    type
    header
    description
    listOfImagesWithPath {
      title
      type
      path
      richText {
        raw
      }
      image {
        file {
          fileName
          url
        }
      }
    }
  }
  fragment ImageWithPath on ContentfulImageWithPath {
    id
    title
    type
    path
    richText {
      raw
    }
    image {
      file {
        url
      }
    }
  }
  fragment ImageContainer on ContentfulImageContainer {
    id
    title
    type
    image {
      file {
        url
      }
    }
    headerWithText {
      headerText
      text {
        text
      }
    }
    assets {
      title
      file {
        fileName
        url
      }
    }
  }
`;
