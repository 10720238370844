import React from 'react';
import DeliveringMore from '../../components/deliverMore/deliveringMore';
import ControlledCarousel from '../../common/carouselTemplate/carouselTemplate';
import HomeBannerList from '../../components/homeBannerList/homeBannerList';
import HomePromosList from '../../components/homePromosList/homePromosList';
import HomePromoBanner from '../../components/homePromoBanner/homePromoBanner';
import ImageWithPath from '../../components/imageWithPath/imageWithPath';
import { graphql, useStaticQuery } from 'gatsby';
import { SECTIONS } from '../../constants';
import CustomerCare from '../../components/dedicatedCustomerCare/dedicatedCustomerCare';
import DedicatedHSE from '../../components/dedicatedHSE/dedicatedHSE';
import Cookies from 'universal-cookie';
import { Helmet } from 'react-helmet';
import HomeBlueSection from '../../components/homeBlueSection/homeBlueSection';
import PropanePopup from '../../components/propanePopup/propanePopup';
import logoSparlings from '../../images/logo-sparlings-og.png';

const HomePageTemplate = () => {
  const homePageSectionsData = useStaticQuery(graphql`
    query HomePageQuery {
      allContentfulSparlings(
        filter: { identifier: { type: { eq: "homePage" } } }
      ) {
        edges {
          node {
            node_locale
            title
            slug
            metadata
            identifier {
              type
            }
            sections {
              __typename
              ... on ContentfulHomeContainers {
                id
                title
                type
                homecontainers {
                  type
                  title
                  label
                  textWithLink1 {
                    ... on ContentfulTextWithPath {
                      id
                      text
                      path
                      type
                    }
                  }
                  textWithLink2 {
                    ... on ContentfulTextWithPath {
                      id
                      text
                      path
                      type
                    }
                    ... on ContentfulImageWithPath {
                      id
                      type
                      title
                      path
                      image {
                        title
                        file {
                          url
                        }
                      }
                      richText {
                        raw
                      }
                    }
                  }
                  backgroundImages {
                    title
                    file {
                      url
                    }
                  }
                }
              }
              ... on ContentfulContentList {
                id
                title
                type
                list {
                  contentDetails {
                    raw
                  }
                  title
                  type
                  header
                }
              }
              ... on ContentfulListOfImageWithPath {
                id
                type
                title
                listOfImagesWithPath {
                  type
                  title
                  path
                  image {
                    title
                    file {
                      url
                      fileName
                    }
                  }
                  richText {
                    raw
                  }
                }
                home_container {
                  type
                  title
                  textWithLink1 {
                    ... on ContentfulImageWithPath {
                      id
                      title
                      type
                      image {
                        file {
                          url
                        }
                        title
                      }
                      richText {
                        raw
                      }
                      path
                    }
                  }
                  textWithLink2 {
                    ... on ContentfulContent {
                      id
                      title
                      type
                      contentDetails {
                        raw
                      }
                      header
                      subText
                    }
                  }
                  textWithLink3 {
                    ... on ContentfulContent {
                      id
                      title
                      type
                      contentDetails {
                        raw
                      }
                      subText
                      header
                    }
                  }
                  backgroundImages {
                    file {
                      url
                    }
                    title
                  }
                }
              }
              ... on ContentfulImageWithPath {
                id
                title
                type
                image {
                  file {
                    url
                  }
                  title
                }
                richText {
                  raw
                }
                path
              }
              ... on ContentfulContent {
                id
                title
                type
                contentDetails {
                  raw
                }
                header
                subText
              }
            }
          }
        }
      }
    }
  `);

  const [showPropaneModal, setShowPropaneModal] = React.useState(false);
  const cookies = new Cookies();
  const regionLanguageFilteredData =
    homePageSectionsData?.allContentfulSparlings?.edges?.find((edge) =>
      cookies.get('language')
        ? edge?.node?.node_locale === cookies.get('language')
        : edge?.node?.node_locale === 'en-US'
    );

  const homePageSections = regionLanguageFilteredData?.node?.sections;

  const homeImagesWithPath = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.HOME_EXPLORE_SECTION;
  })[0]?.homecontainers;

  const promosDataList = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.PROMO_CONTENTLIST_ITEMS;
  })[0]?.list;

  const customerCareList = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.CUSTOMER_CARE;
  })[0].list;

  const yourPropaneCompany = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.YOUR_PROPANE_COMPANY;
  })[0];

  const orderPropane = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.ORDER_PROPANE;
  })[0];

  const carouselImageData = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.IMAGES_CAROUSEL;
  })[0]?.listOfImagesWithPath;

  const deliveringMoreData = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.DELIVERING_MORE_LIST.DEFAULT;
  })[0]?.listSection;

  const dedicatedHSE = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.FOUNDATION_HSE;
  })[0]?.list;

  // const homePromo = homePageSections?.filter((section) => {
  //   return section?.type === SECTIONS.HOMEPAGE.FALL_PROMOTION;
  // })[0];

  const homePromo = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.ANNOUNCEMENT;
  })[0];

  const homeImageWithPath = homePageSections?.filter((section) => {
    return section?.type === SECTIONS.HOMEPAGE.IMAGE_WITH_PATH;
  })[0];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: regionLanguageFilteredData?.node?.node_locale || 'en',
        }}
      >
        <title>
          {regionLanguageFilteredData?.node?.title
            ? regionLanguageFilteredData?.node?.title + ' | Sparlings Propane'
            : 'Energy Solutions for Residential and Businesses | Sparlings Propane'}
        </title>

        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        {regionLanguageFilteredData?.node?.metadata && (
          <meta
            property="og:description"
            content={regionLanguageFilteredData?.node?.metadata}
          ></meta>
        )}
        {regionLanguageFilteredData?.node?.title && (
          <meta
            property="og:title"
            content={regionLanguageFilteredData?.node?.title}
          ></meta>
        )}
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={logoSparlings}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        {regionLanguageFilteredData?.node?.title && (
          <meta
            property="twitter:title"
            content={regionLanguageFilteredData?.node?.title}
          ></meta>
        )}
        {regionLanguageFilteredData?.node?.metadata && (
          <meta
            property="twitter:description"
            content={regionLanguageFilteredData?.node?.metadata}
          ></meta>
        )}
        <meta name="twitter:image" content={logoSparlings}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <HomeBannerList homeBannerList={homeImagesWithPath} />
      {/* <HomePromoBanner homeBanner={homePromo} /> */}
      {/* <ImageWithPath imageWithPath={homeImageWithPath} /> */}
      {/* <HomePromoBanner homeBanner={homePromo} /> */}
      <HomePromoBanner homeBanner={homeImageWithPath} />
      {/* <ImageWithPath
        sectionData={homeImageWithPath}
        location={homeImageWithPath}
      /> */}
      <HomePromosList homePromosList={promosDataList} />
      <HomeBlueSection
        yourPropaneCompany={yourPropaneCompany}
        orderPropane={orderPropane}
        showModal={setShowPropaneModal}
      />
      <CustomerCare customerCareData={customerCareList} />
      <DedicatedHSE dedicatedHSEData={dedicatedHSE} />
      {showPropaneModal && <PropanePopup showModal={setShowPropaneModal} />}
    </>
  );
};
export default HomePageTemplate;
